<template>
  <div v-loading="loading" element-loading-spinner="el-loading-spinner" element-loading-background="rgba(0, 0, 0, 0.1)">
    <el-container style="height: 100vh; display: flex; flex-direction: column">
      <drawerHeader :step="automationRecord"></drawerHeader>
      <!-- Tabs -->
      <el-row>
        <el-tabs v-model="activeTab" style="flex-grow: 1" @tab-click="handleTabs">
          <appEventComponent :step="automationRecord" :eventType="eventType"></appEventComponent>
          <connectionsComponent :step="automationRecord" v-if="automationRecord.app_id.isAuthenticating">
          </connectionsComponent>
          <el-tab-pane label="Trigger" class="trigger_size" name="trigger" key="trigger"
            :disabled="automationRecord.app_connection_id ? false : true" v-if="checkDomainFields()">
            <renderFields v-if="automationRecord.form_data" :key="automationRecord.app_connection_id"
              :fields="automationRecord.form_data" :automationInfo="automationRecord" :isTrigger="true" />
          </el-tab-pane>
          <!-- <div class="scroll-container"> -->
          <el-tab-pane class="scroll-container" label="Test" key="test" name="test" :disabled="isTestTabActive">
            <el-row class="webhook-container" :gutter="10" v-if="this.webhookData" v-model="this.webhookData">
              <!-- Header Section -->
              <el-col :span="24">
                <div class="header">Your webhook URL</div>
              </el-col>

              <!-- Subheader Section -->
              <el-col :span="24">
                <div class="subheader">
                  You'll need to configure your application with this
                  Automationflow webhook URL.
                </div>
              </el-col>

              <!-- Webhook URL Input and Copy Button -->
              <el-col :span="24">
                <div class="input-copy-container">
                  <el-input class="webhook-input" v-model="webhookData.webhookUrl" disabled></el-input>
                  <el-button class="copy-button" type="primary" @click="copyWebhookUrl">
                    Copy
                  </el-button>
                  <!-- Display copied message -->
                  <span v-if="copied" class="copied-message">Copied!</span>
                </div>
              </el-col>

              <!-- Note Section -->
              <el-col :span="24">
                <ul class="list-items-webhook" align="left" style="color: #606366">
                  <li v-for="(step, index) in webhookData.webhookInstructions" :key="index" style="margin-top: 5px">
                    <span v-html="convertUrlsToLinks(step)"></span>
                  </li>
                </ul>
              </el-col>
            </el-row>
            <el-button shadow="always" size="large" type="primary" :loading="submitLoading" style="
                width: 100%;
                font-size: 17px;
                height: 40px;
                border: none;
                background-color: #f754a2;
                margin-bottom: 10px;
                margin-top: 10px;
              " @click="findRecords" class="custom-button btn-clr">
              <span>Find Records</span>
            </el-button>
            <responseDisplayTable v-if="tableData" :response="tableData" :key="responseData"></responseDisplayTable>
          </el-tab-pane>
          <!-- </div> -->
          <el-button shadow="always" size="large" type="primary" :loading="submitLoading" style="
              width: 42%;
              font-size: 17px;
              height: 48px;
              border: none;
              background-color: #f754a2;
              margin-bottom: 10px;
              margin-top: 10px;
              position: fixed;
              bottom: 0;
            " @click="goToNextTab" class="connectButton-propsObject">
            <span>Continue</span>
          </el-button>
        </el-tabs>
      </el-row>
    </el-container>
  </div>
</template>
<script>
import store from "@/store/index";
import { mapGetters } from "vuex";
import renderFields from "@/components/connectorApp/renderFields.vue";
import appEventComponent from "./appEventComponent.vue";
import connectionsComponent from "./connectionsComponent.vue";
import responseDisplayTable from "./responseDisplayTable.vue";
import drawerHeader from "./drawerHeader.vue";
export default {
  components: {
    renderFields,
    appEventComponent,
    connectionsComponent,
    responseDisplayTable,
    drawerHeader,
  },
  props: {
    automationInfo: {
      type: String,
      required: true,
    },
    eventType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      updateConnectionDialog: false,
      deleteConnectionDialog: false,
      updateConnectionsData: false,
      textareaOfDialog: "",
      loading: false,
      activeTab: this.$route.params.tab ? this.$route.params.tab : "appEvent",
      webhookRequest: {},
      automationRecord: null,
      tableData: null,
      event: null,
      isTestTabActive: this.displayTestTab(),
      webhookData: null,
    };
  },
  computed: {
    ...mapGetters("globalApis", [
      "getAllConnections",
      "getOauthServerUrl",
      "getOauthTokens",
      "getAppFieldsByName",
      "getUpdateTriggerAppData",
      "getWebhookRequest",
      "getWebhookInstructions",
      "getReviewFieldsResponse",
      "getWebhookInThirdParty",
    ]),
  },
  created() {
    this.emitter.on("bindDepencyFieldsInParent", (data) => {
      this.listenEventDependencyFields(data);
    });
  },
  async beforeMount() {
    await this.fetchAutomationFlowById();
  },
  methods: {
    checkDomainFields() {
      let appInfo = this.automationInfo.app_id;
      let macthedEvent = appInfo.trigger_events.find(
        (event) => event._id == this.automationInfo.event_id
      );
      if (macthedEvent && macthedEvent.domain_fields) {
        return true;
      } else {
        return false;
      }
    },
    displayTestTab() {
      let isPresent = this.checkDomainFields();
      if (isPresent && this.automationInfo.form_data) {
        return false;
      } else if (this.automationInfo.app_connection_id) {
        return false;
      } else {
        return true;
      }
    },
    async fetchAutomationFlowById() {
      this.automationRecord = this.automationInfo;
      this.$router.push({
        name: "workflow",
        params: {
          automationFlowId: this.$route.params.automationFlowId,
          actionId: this.$route.params.actionId,
          tab: this.activeTab,
          step: this.$route.params.step,
        },
      });
    },
    async deleteConnectionEvent() {
      let connectionId = this.connectionModel._id;
      let response = await this.deleteConnection(connectionId);
      if (response.status) {
        let indexValue = await this.connections.findIndex(
          (obj) => obj._id == connectionId
        );
        this.connections.splice(indexValue, 1);
        this.connectionModel = null;
        this.deleteConnectionDialog = false;
        this.$notify.success({
          title: "Success",
          message: response.message,
        });
      } else {
        this.$notify.error({
          title: "Error",
          message: response.message,
        });
      }
    },
    async updateConnectionNameEvent() {
      let name = this.textareaOfDialog;
      let connectionId = this.connectionModel._id;
      let response = await this.updateConnectionName(name, connectionId);
      if (response.status) {
        let matchedId = await this.connections.find(
          (obj) => obj._id == connectionId
        );
        matchedId.name = name;
        this.updateConnectionDialog = false;
        this.$notify.success({
          title: "Success",
          message: response.message,
        });
      } else {
        this.$notify.error({
          title: "Error",
          message: response.message,
        });
      }
    },
    async updateConnection() {
      let connectionId = this.connectionModel._id;
      let userId = this.automationRecord.user_id;
      let response = await this.updateConnection(connectionId, userId);
      if (response.status) {
        this.$notify.success({
          title: "Success",
          message: response.message,
        });
      } else {
        this.$notify.error({
          title: "Error",
          message: response.message,
        });
      }
    },
    async SaveExisistingConnection() {
      await this.updateTriggerAppInfo();
      this.activeTab = "trigger";
    },
    async openTriggerAllApps() {
      this.emitter.emit("openTriggerAppsList", false);
    },
    async updateTriggerAppInfo() {
      let params = {
        app_id: this.automationRecord.app_id._id,
        app_connection_id: this.automationRecord.app_connection_id
          ? this.automationRecord.app_connection_id
          : null,
        _id: this.automationRecord._id,
        event_id: this.automationRecord.event_id
          ? this.automationRecord.event_id
          : null,
        webhookInfo: this.automationRecord.webhookInfo
          ? this.automationRecord.webhookInfo
          : null,
        form_data: this.automationRecord.form_data
          ? this.automationRecord.form_data
          : null,
      };
      this.loading = true;
      await store.dispatch(
        "globalApis/updateAutomationFlowWithTrigger",
        params
      );
      this.loading = false;
    },
    async saveTriggerEventData() {
      await this.updateTriggerAppInfo();
      await this.registerWebhook();
      await this.addWebhook();
      this.activeTab = "test";
    },
    async registerWebhook() {
      let webhookBody = {
        appName: this.automationRecord.app_id.app_name_key,
        event: this.automationRecord.form_data,
        automationId: this.automationRecord._id,
        isCron: false,
      };
      this.loading = true;
      await store.dispatch(
        "globalApis/registerWebhookInThirdParty",
        webhookBody
      );
      this.loading = false;
    },
    async addWebhook() {
      let webhookBody = {
        appName: this.automationRecord.app_id.app_name_key,
        event: this.automationRecord.form_data,
        user_id: this.automationRecord.user_id,
        automationId: this.automationRecord._id,
        isCron: false,
      };
      this.loading = true;
      await store.dispatch("globalApis/addWebhookInThirdParty", webhookBody);
      this.loading = false;
      this.webhookData = this.getWebhookInThirdParty.data;
    },
    convertUrlsToLinks(text) {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      const urlsInText = text.match(urlRegex) || [];
      this.new = urlsInText;
      const textWithoutUrls = text.replace(urlRegex, "");
      return textWithoutUrls.replace(/<([^>]+)>/g, (_, content) =>
        this.getClickHereLink(content)
      );
    },
    getClickHereLink(content) {
      return `<a href="${this.new
        }" style="color: #409eff; cursor: pointer;" target="_blank">${content ? " " + content : ""
        }</a>`;
    },
    copyWebhookUrl() {
      // Create a temporary textarea to hold the webhook URL
      const textarea = document.createElement("textarea");
      textarea.value = this.webhookData.webhookUrl;
      document.body.appendChild(textarea);

      // Select the content and copy it to the clipboard
      textarea.select();
      document.execCommand("copy");

      // Remove the textarea
      document.body.removeChild(textarea);
      this.$message.success("Link copied successfully");
      // Show copied message
      this.copied = true;

      // Hide the copied message after 2 seconds
      setTimeout(() => {
        this.copied = false;
      }, 2000);
    },
    async findRecords() {
      try {
        this.loading = true;
        await store.dispatch(
          "globalApis/findTriggerRecords",
          this.automationRecord._id
        );
        this.loading = false;
        if (!this.getWebhookRequest || !this.getWebhookRequest.data.structuredData || this.getWebhookRequest.data.structuredData.length === 0) {
      this.$notify.error({
        title: "Error",
        message: "Please ensure that the necessary data is in the trigger application.",
      });
      return;
    }
        this.tableData = this.getWebhookRequest.data.structuredData;
      } catch (err) {
        this.loading = false;
    this.$notify.error({
      title: "Error",
      message: "An error occurred while fetching records. Please try again.",
    });
        return err;
      }
    },
    // async getNestedFields(ParentValue, parentIndex) {
    //   let params = {
    //     connection_id: this.automationRecord.app_connection_id,
    //     data: ParentValue,
    //     index: parentIndex,
    //     _id: this.automationRecord._id,
    //   };
    //   try {
    //     this.loading = true;
    //     await store.dispatch("fetchNestedAppFieldsByName", params);
    //     this.loading = false;
    //     if (this.getNestedAppFields && this.getNestedAppFields.data) {
    //       this.automationRecord.requiredIds.labels[
    //         parentIndex
    //       ] = this.getNestedAppFields.data;
    //     }
    //   } catch (error) {
    //     return error;
    //   }
    // },
    // async getChildFields(childValue, childIndex, parentIndex) {
    //   let params = {
    //     connection_id: this.automationRecord.app_connection_id,
    //     data: childValue,
    //     childIndex: childIndex,
    //     parentIndex: parentIndex,
    //   };
    //   try {
    //     this.loading = true;
    //     await store.dispatch("fetchChildFieldsByName", params);
    //     this.loading = false;
    //     if (this.getChildAppFields && this.getChildAppFields.data) {
    //       this.fieldsObject.labels[parentIndex].children[
    //         childIndex + 1
    //       ].value = this.getChildAppFields.data;
    //     }
    //   } catch (error) {
    //     return error;
    //   }
    // },
    async fetchWebhookInstructions(appName) {
      this.loading = true;
      await this.$store.dispatch("globalApis/webhookInstructions", appName);
      this.loading = false;
      this.webhookData = this.getWebhookInstructions.data;
    },
    async goToNextTab() {
      if (this.activeTab == "appEvent") {
        await this.updateTriggerAppInfo();
        if (this.automationRecord.app_id.isAuthenticating) {
          this.activeTab = "account";
        } else {
          if (this.checkDomainFields()) {
            this.activeTab = "trigger";
          } else {
            this.activeTab = "test";
          }
        }
      } else if (this.activeTab == "account") {
        if (this.checkDomainFields()) {
          if (!this.automationRecord.form_data) {
            await this.fetchAppFields();
          }
          await this.updateTriggerAppInfo();
          this.activeTab = "trigger";
        } else {
          this.saveTriggerEventData();
        }
      } else if (this.activeTab == "trigger") {
        this.saveTriggerEventData();
        // this.activeTab = "test";
      } else if (this.activeTab == "test") {
        await this.updateTriggerData();
        this.activeTab = "appEvent";
      }
    },
    async updateTriggerData() {
      try {
        if (
          !this.getWebhookRequest ||
          !this.getWebhookRequest.data ||
          !this.getWebhookRequest.data.details
        ) {
          this.$notify.error({
            title: "Error",
            message: "Unable to update trigger data. Required data is missing.",
          });
          return;
        }
        let payload = this.getWebhookRequest.data.details;
        this.loading = true;
        await store.dispatch("globalApis/updateTriggerRecord", payload);
        this.loading = false;
        this.$notify.success({
          title: "Success",
          message: "Trigger data updated successfully.",
        });
      } catch (err) {
        this.loading = false;
        console.error("Error in updateTriggerData:", err);
        this.$notify.error({
          title: "Update Failed",
          message:
            "There was an error while updating the trigger data. Please try again.",
        });
        return err;
      }
    },
    //   async updateTriggerData() {
    //   let payload = this.getWebhookRequest.data.details;
    //   this.loading = true;
    //   await store.dispatch("globalApis/updateTriggerRecord", payload);
    //   this.loading = false;
    // },
    async selectEvent() {
      await this.updateTriggerAppInfo();
    },
    async fetchAppFields() {
      let eventFields = await this.automationRecord.app_id.trigger_events.find(
        (event) => event._id == this.automationRecord.event_id
      );
      let params = {
        fieldsSet: eventFields,
        connectionId: this.automationRecord.app_connection_id,
        automationId: this.automationRecord._id,
        appName: this.automationRecord.app_id.app_name_key,
      };
      this.loading = true;
      await store.dispatch("globalApis/reviewFields", params);
      this.loading = false;
      this.automationRecord.form_data =
        this.getReviewFieldsResponse.data.fieldsSet.domain_fields;
    },
    async selectConnection() {
      await this.updateTriggerAppInfo();
    },
    listenEventDependencyFields(data) {
      this.automationRecord.form_data = data;
    },
    async handleTabs(tab) {
      this.activeTab = tab.props.name;
      this.$router.push({
        name: "workflow",
        params: {
          automationFlowId: this.$route.params.automationFlowId,
          actionId: this.$route.params.actionId,
          tab: this.activeTab,
          step: this.$route.params.step,
        },
      });
      if (tab.props.name == "trigger") {
        if (!this.automationRecord.form_data) {
          await this.fetchAppFields();
        }
      } else if (tab.props.name == "test") {
        await this.addWebhook();
      }
    },
  },
};
</script>
<style>
.ui-card {
  margin: 20px;
}

.ui-content {
  margin: 20px;
}

.wide-input {
  width: 260px;
}

.info-section {
  color: darkgray;
  font-size: 13px;
  font-weight: 100;
}

.info-text {
  color: darkgray;
  font-size: 13px;
  font-weight: 100;
}

.info-link {
  color: cornflowerblue;
}

.drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  color: #1b4554;
  position: fixed;
  height: 90px;
  top: 0;
  left: 50;
  right: 0;
  z-index: 999;
}

.drawer {
  top: 0;
  position: fixed;
  display: flex;
  align-items: flex-start;
  background-color: #ffffff;
  padding: 1%;
  height: 80px;
  border: 1px solid #e5e5e5;
  z-index: 1;
  right: 0;
  width: 35%;
}

.drawer-title-p {
  color: #909399;
  font-size: 14px;
  padding-top: 2%;
}

.drawer-title {
  color: #303133;
  font-size: 18px;
}

.drawer-logo {
  border-radius: 0px;
  border: none;
  display: flex;
  align-items: center;
}

.select-box .el-input__inner {
  width: 340px;
}

.oauth-connect-button {
  border-radius: 0px;
}

.drawer-card-header {
  color: #303133;
  font-size: 16px;
}

.drawer-footer {
  bottom: 0;
  right: 0;
  left: 0;
}

.button-connect-drawer {
  border-radius: 0px;
}

.textStyle {
  font-size: 18px;
}

.drawer_slide {
  top: 50px;
  position: fixed;
  display: flex;
  align-items: flex-start;
  background-color: #ffffff;
  padding: 1%;
  height: 80px;
  border: 1px solid #e5e5e5;
  /* z-index: 1; */
  right: 0;
  width: 45%;
}

.container {
  margin-top: 1%;
  width: 100%;
}

.webhook-container {
  padding: 5%;
  /* Adjusted to use percentage for padding */
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  height: 100%;
  max-width: 100%;
  /* Adjusted max-width to percentage */
  margin: 0 auto;
}

.header {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 2.5%;
  /* Adjusted to use percentage for margin-bottom */
}

.subheader {
  font-size: 1.1em;
  margin-bottom: 2%;
  /* Adjusted to use percentage for margin-bottom */
  background-color: #e8f0fe;
  padding: 2.5%;
  /* Adjusted to use percentage for padding */
  border-radius: 3px;
}

.note {
  font-size: 0.9em;
  color: #555;
  margin-top: 2%;
  /* Added margin-top for spacing */
}

.note code {
  background-color: #f1f1f1;
  padding: 1%;
  /* Adjusted to use percentage for padding */
  border-radius: 3px;
}

.note a {
  color: #4285f4;
  text-decoration: none;
}

.input-copy-container {
  display: flex;
  width: 100%;
  align-items: center;
}

.webhook-input {
  flex: 4;
  /* 80% of width */
  margin-right: 2%;
  /* Space between input and button */
}

.copy-button {
  flex: 1;
  /* 20% of width */
  width: auto;
  /* Ensure the button doesn't overflow */
}

.copied-message {
  margin-left: 10px;
  color: green;
  font-weight: bold;
  font-size: 14px;
}

.webhook-and-find-records {
  height: auto;
  /* Ensure auto or defined height */
  max-height: 100%;
  /* Avoid content overflow */
  padding-bottom: 0;
}

.scroll-container {
  box-sizing: border-box;
  max-height: 460px;
  /* Set appropriate max-height */
  overflow-y: auto;
  padding-right: 10px;
  padding-left: 5px;
  /* margin-bottom: 0; */
  /* padding-bottom: 0; */
  width: 100%;
  /* Ensure width is set to 100% */
}

#pane-test {
  max-height: 460px;
}

.scroll-container::-webkit-scrollbar {
  width: 8px;
  /* Width of the scrollbar */
}

.scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Scrollbar track */
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Scrollbar color */
  border-radius: 10px;
  /* Rounded corners */
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Hover effect */
}
</style>
